<template>
    <!-- 查看巡检计划 -->
    <div class="box-card" style="text-align: left;">
      <div class="title">基本信息</div>
      <el-divider></el-divider>
      <el-form label-width="130px" class="detailForm">
        <el-form-item label="巡检计划：">{{detailForm.plan_name}}</el-form-item>
        <el-form-item label="资产项目：">{{detailForm.project_name}}</el-form-item>
        <el-form-item label="巡检路线：">{{detailForm.route_name}}</el-form-item>
        <el-form-item label="起止日期：">{{  $moment(detailForm.plan_start_time).format('YYYY-MM-DD')+' 至 '+ $moment(detailForm.plan_end_time).format('YYYY-MM-DD')}}</el-form-item>
        <el-form-item label="开启时间：">{{detailForm.open_time}}</el-form-item>
        <el-form-item label="巡检周期：">{{ getWorkType(detailForm.work_type)}}
          <span v-if="detailForm.work_type != 1">（{{detailForm.workSet}}）</span>
        </el-form-item>
        <el-form-item label="状态：">
          <span class="plan_status_box" :style=" 'background-color:' + planStatusColor(detailForm.plan_status)">
            <!-- {{getWayName("PlanStatus", detailForm.plan_status)}} -->
            {{planStatus(detailForm.plan_status)}}
          </span>
        </el-form-item>
      </el-form>
      <div class="title">巡检班次</div>
      <el-divider></el-divider>
      <el-date-picker v-model="data" :picker-options="pickerOptions" :clearable="false" type="date" value-format="yyyy-MM-dd" @change="onSubmit()" :editable="false" placeholder="请选择时间"></el-date-picker>
      <!-- 表格 -->
      <el-table ref="multipleTable" :data="detailForm.planDetail" tooltip-effect="dark" style="width: 100%;margin-top:15px;" border stripe>
        <el-table-column label="巡检时段">
          <template slot-scope="scope">
                {{ $moment(scope.row.start_time).format("HH:mm:ss") }} ~ {{ $moment(scope.row.end_time).format("HH:mm:ss") }}
              </template>
        </el-table-column>
        <el-table-column prop="inspector_name" label="巡检人员"></el-table-column>
        <el-table-column label="任务进度">
           <template slot-scope="scope">
                {{scope.row.dealCount ? scope.row.dealCount: 0 }} / {{scope.row.inspectionCount ? scope.row.inspectionCount: 0}}
              </template>
        </el-table-column>
        <el-table-column label="巡检状态">
           <template slot-scope="scope">
                <!-- 1 待执行 2 执行中 3 已完成 4 已终止 5 已过期 -->
                <span :style="'color:' + taskStatusColor(scope.row.task_status)">
                  {{getWayName("AssetTaskStatus", scope.row.task_status)}}
                </span>
              </template>
        </el-table-column>
      </el-table>

    </div>
  </template>

<script>
import { getInspectionPlanList, addInspectionPlan, updateInspectionPlan, delInspectionPlan, getInspectorSelectList, getInspectorPlanUpdateDetail, setInspectionPlanStatus, getInspectorPlanDetail } from '@/api/assetsManagement'
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      detailForm: {},
      data: '',
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  created () {
    this.data = this.$moment().format('YYYY-MM-DD')
    this.fnGetInspectorPlanDetail()
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取巡检计划详情
    async fnGetInspectorPlanDetail () {
      const res = await getInspectorPlanDetail({
        Id: this.id,
        select_date: this.data
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.detailForm = res.Data.inspectionPlan[0]
        this.detailForm.planDetail = res.Data.planDetail
        this.detailForm.work_set = this.detailForm.work_set ? this.detailForm.work_set.split(',').filter((s) => {
          return s // 注：IE9(不包含IE9)以下的版本没有trim()方法
        }) : []
        console.log(this.detailForm.work_set)
        if (this.detailForm.work_set.length > 0) {
          var arr = []
          this.detailForm.work_set.forEach((item) => {
            if (this.detailForm.work_type === 2) { // 周
              arr.push(this.getWorkData(Number(item)))
            } else if (this.detailForm.work_type === 3) { // 自定义
              arr.push(item + '日')
            }
          })
          this.detailForm.workSet = arr.join('、')
          console.log(this.detailForm.workSet)
        }
      }
    },
    onSubmit () {
      this.fnGetInspectorPlanDetail()
    },
    getWorkType (type) {
      switch (type) {
        case 1:
          return '每天'
        case 2:
          return '周'
        case 3:
          return '自定义'
      }
    },
    getWorkData (type) {
      switch (type) {
        case 1:
          return '周一'
        case 2:
          return '周二'
        case 3:
          return '周三'
        case 4:
          return '周四'
        case 5:
          return '周五'
        case 6:
          return '周六'
        case 7:
          return '周日'
      }
    },
    // 返回
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    }

  }
}
</script>

  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
  }
  .detailForm {
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }
  .plan_status_box {
    padding: 5px 10px;
    color: #fff;
  }
  </style>
