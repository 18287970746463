<template>
    <!-- 新增巡检计划 -->
    <div class="box-card" style="text-align: left;">
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
            <div class="title">基本信息</div>
            <el-divider></el-divider>
            <el-form-item label="巡检计划：" prop="plan_name">
                <el-input v-model="addForm.plan_name" maxlength="30" placeholder="请输入巡检计划"
                    @input="(e) => (addForm.plan_name = validSpace(e))"></el-input>
            </el-form-item>
            <el-form-item label="资产项目：" prop="project_id">
                <el-select popper-class="my-select" v-model="addForm.project_id" placeholder="请选择资产项目"
                    @change="projectChange" filterable>
                    <el-option v-for="item in assetProjectSelectList" :key="item.Id" :label="item.project_name"
                        :value="item.Id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="巡检路线：" prop="route_id">
                <el-select popper-class="my-select" v-model="addForm.route_id" placeholder="请选择巡检路线" filterable>
                    <el-option v-for="item in inspectionRouteSelectList" :key="item.Id" :label="item.route_name"
                        :value="item.Id"></el-option>
                    <!-- <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option> -->
                </el-select>
            </el-form-item>
            <el-form-item label="起止日期：" prop="timeduan">
                <div style="display:flex;">
                    <el-date-picker v-model="addForm.timeduan" value-format="yyyy-MM-dd" type="daterange"
                        range-separator="至" start-placeholder="请选择开始日期" end-placeholder="请选择结束日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-form-item prop="open_time" style="width:200px;margin-left:15px;">
                        <!-- <el-time-picker v-model="addForm.open_time" value-format="HH:mm" format="HH:mm" placeholder="请选择开启时间" style="width:100%"></el-time-picker> -->
                        <el-time-select popper-class="my-select" format="HH:mm" placeholder="请选择开启时间" style="width:100%"
                            v-model="addForm.open_time" :picker-options="{
                                    start: '00:00',
                                    step: '00:30',
                                    end: '23:30',
                                    minTime: startTime,
                                }">
                        </el-time-select>
                    </el-form-item>
                </div>
            </el-form-item>
            <el-form-item label="巡检周期：" prop="work_type" class="work_type_item">
                <el-select popper-class="my-select" v-model="addForm.work_type" placeholder="请选择巡检周期"
                    @change="addForm.work_set = []">
                    <el-option label="每天" :value="1"></el-option>
                    <el-option label="周" :value="2"></el-option>
                    <el-option label="自定义" :value="3"></el-option>
                </el-select>
                <el-form-item style="margin-left: 15px;" v-if="addForm.work_type == 2" prop="work_set">
                    <el-select popper-class="my-select" v-model="addForm.work_set" multiple clear placeholder="请选择周期参数">
                        <el-option label="周一" :value="'1'"></el-option>
                        <el-option label="周二" :value="'2'"></el-option>
                        <el-option label="周三" :value="'3'"></el-option>
                        <el-option label="周四" :value="'4'"></el-option>
                        <el-option label="周五" :value="'5'"></el-option>
                        <el-option label="周六" :value="'6'"></el-option>
                        <el-option label="周日" :value="'7'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="margin-left: 15px;" v-if="addForm.work_type == 3" prop="work_set">
                    <el-select popper-class="my-select" v-model="addForm.work_set" multiple clear placeholder="请选择周期参数"
                        filterable>
                        <el-option v-for="(item, index) in customSelectList" :key="index" :label="item"
                            :value="item"></el-option>
                    </el-select>
                </el-form-item>
            </el-form-item>
            <div class="height_box"></div>
            <div class="title">巡检班次</div>
            <el-divider></el-divider>
            <el-form-item class="time-frame" prop="list_inspection_classes">
                <div v-for="(item, index) in addForm.list_inspection_classes" :key="index" class="content_box">
                    <div class="title_box">巡检时段：</div>
                    <el-form-item :prop="'list_inspection_classes[' + index + '].start_time'"
                        :rules="{ required: true, message: '请选择开始时间', trigger: 'blur' }">
                        <el-time-picker v-model="item.start_time"
                            :default-value="$moment().format('YYYY-MM-DD ') + '00:00:00'" value-format="HH:mm:ss"
                            format="HH:mm" :picker-options="{ selectableRange: toStartTime(index), format: 'HH:mm' }"
                            placeholder="请选择开始时间" @change="changeStartTime(index, item.start_time)"></el-time-picker>
                    </el-form-item>
                    <span class="span_box">~</span>
                    <el-form-item :prop="'list_inspection_classes[' + index + '].end_time'"
                        :rules="{ required: true, message: '请选择结束时间', trigger: 'blur' }">
                        <el-time-picker v-model="item.end_time"
                            :default-value="$moment().format('YYYY-MM-DD ') + '23:59:59'" value-format="HH:mm:ss"
                            format="HH:mm" :picker-options="{ selectableRange: toEndTime(index), format: 'HH:mm' }"
                            placeholder="请选择结束时间" @change="changeEndTime(index, item.end_time)"></el-time-picker>
                    </el-form-item>
                    <div class="title_box">巡检人员：</div>
                    <el-form-item :prop="'list_inspection_classes[' + index + '].inspector_id'"
                        :rules="{ required: true, message: '请选择巡检人员', trigger: 'blur' }">
                        <el-select popper-class="my-select" v-model="item.inspector_id" placeholder="请选择巡检人员">
                            <el-option v-for="item in inspectorSelectList" :key="item.Id" :label="item.user_name"
                                :value="item.Id"></el-option>
                            <!-- <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option> -->
                        </el-select>
                    </el-form-item>
                    <el-button style="height:30px;margin-left:15px;padding:8px!important;" icon="el-icon-plus" size="mini"
                        circle v-if="index < 3" @click="addInspectionClasses(index)"></el-button>
                    <el-button style="height:30px;margin-left:15px;padding:8px!important;" icon="el-icon-minus" size="mini"
                        circle v-if="index > 0" @click="delInspectionClasses(index)"></el-button>
                </div>
            </el-form-item>
        </el-form>
        <!-- <lbButton type="goBack" icon="back" class="checkButton3" @click="defaultForm">返 回</lbButton> -->
        <!-- plan_status 状态 1：待开启 2：开启 -->
        <lbButton class="checkButton2" type="warning" fill icon="evasave" @click="submitAddForm(1)" v-preventReClick>仅保存</lbButton>
        <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm(2)" v-preventReClick>保存并开启
        </lbButton>
    </div>
</template>

<script>
import axios from 'axios'
import { getAssetProjectSelectList, getInspectionRouteSelectList, getInspectionPlanList, addInspectionPlan, updateInspectionPlan, delInspectionPlan, getInspectorSelectList, getInspectorPlanUpdateDetail, setInspectionPlanStatus, getInspectorPlanDetail } from '@/api/assetsManagement'
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      title: '',
      // 最小时间范围
      startTime: '00:00',
      addForm: {
        plan_name: '',
        project_id: '',
        route_id: '',
        timeduan: '',
        plan_start_time: '',
        plan_end_time: '',
        work_type: '',
        work_set: [],
        open_time: '06:00',
        list_inspection_classes: [
          {
            start_time: '',
            end_time: '',
            inspector_id: ''
          }
        ]
      },
      addFormRules: {
        plan_name: [
          { required: true, message: '请输入巡检计划', trigger: 'blur' }
        ],
        open_time: [
          { required: true, message: '请选择计划开启时间', trigger: 'blur' }
        ],
        project_id: [
          { required: true, message: '请选择资产项目', trigger: 'blur' }
        ],
        route_id: [
          { required: true, message: '请选择巡检路线', trigger: 'blur' }
        ],
        timeduan: [
          { required: true, message: '请选择起止日期', trigger: 'blur' }
        ],
        work_type: [
          { required: true, message: '请选择巡检周期', trigger: 'blur' }
        ],
        work_set: [
          { type: 'array', required: true, message: '请选择周期参数', trigger: 'blur' }
        ],
        list_inspection_classes: [
          { type: 'array', required: true, message: '请配置巡检班次', trigger: 'blur' }
        ]
      },
      customSelectList: ['1', ' 2', ' 3', ' 4', ' 5', ' 6', ' 7', ' 8', ' 9', ' 10', ' 11', ' 12', ' 13', ' 14', ' 15', ' 16', ' 17', ' 18', ' 19', ' 20', ' 21', ' 22', ' 23', ' 24', ' 25', ' 26', ' 27', ' 28', ' 29', ' 30', ' 31'],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      // 资产项目下拉列表
      assetProjectSelectList: [],
      // 巡检人员下拉列表
      inspectorSelectList: [],
      // 巡检路线下拉列表
      inspectionRouteSelectList: []
    }
  },
  created () {
    this.fnGetSelect()
    if (this.id === 'new') {
      this.title = '新增巡检计划'
    } else {
      this.title = '编辑巡检计划'
      this.fnGetInspectorPlanUpdateDetail()
    }
  },
  watch: {
    'addForm.timeduan' (newV, oldV) {
      if (newV) {
        this.addForm.plan_start_time = newV[0]
        this.addForm.plan_end_time = newV[1]
        if (newV[0] === this.$moment().format('YYYY-MM-DD')) {
          const remainder = 30 - (this.$moment().minute() % 30)
          const dateTime = this.$moment(this.$moment()).add(remainder, 'minutes').format('HH:mm')
          this.addForm.open_time = dateTime
          this.startTime = dateTime
        } else {
          this.startTime = '00:00'
        }
      } else {
        this.addForm.plan_start_time = ''
        this.addForm.plan_end_time = ''
      }
    }
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 获取下拉列
    fnGetSelect () {
      var that = this
      axios.all([this.fnGetAssetProjectSelectList(), this.fnGetInspectorSelectList()]).then(
        axios.spread(function (project, inspector) {
          // console.log('获取资产项目下拉列------', project);
          that.assetProjectSelectList = project && project.Code === 200 ? project.Data : []
          // console.log('获取巡检人员下拉列******', inspector);
          that.inspectorSelectList = inspector && inspector.Code === 200 ? inspector.Data : []
          console.log('fnGetSelect---', that.addForm.work_set)
        }))
    },
    // 获取巡检计划详情
    async fnGetInspectorPlanUpdateDetail () {
      const res = await getInspectorPlanUpdateDetail({
        Id: this.id
      })
      console.log('获取巡检计划详情---', res)
      if (res && res.Code === 200) {
        var detail = res.Data.planDetail
        this.fnGetInspectionRouteSelectList(detail.project_id)
        var that = this
        this.$nextTick(() => {
          that.addForm.plan_name = detail.plan_name
          that.addForm.project_id = detail.project_id
          that.addForm.route_id = detail.route_id
          that.addForm.open_time = detail.open_time
          that.addForm.timeduan = [this.$moment(detail.plan_start_time).format('YYYY-MM-DD'), this.$moment(detail.plan_end_time).format('YYYY-MM-DD')]
          that.addForm.work_type = detail.work_type
          that.addForm.work_set = detail.work_set ? detail.work_set.split(',').filter((s) => {
            return s // 注：IE9(不包含IE9)以下的版本没有trim()方法
          }) : []
          that.addForm.list_inspection_classes = res.Data.planClasses
        })
      }
    },
    // 获取资产项目下拉列
    async fnGetAssetProjectSelectList () {
      return await getAssetProjectSelectList()
    },
    // 获取巡检人员下拉列
    async fnGetInspectorSelectList () {
      return await getInspectorSelectList({
        person_type: 1
      })
    },
    // 获取巡检路线下拉列
    async fnGetInspectionRouteSelectList (id) {
      const res = await getInspectionRouteSelectList({
        project_id: id
      })
      console.log(res)
      this.inspectionRouteSelectList = res && res.Code === 200 ? res.Data : []
    },
    // 资产项目发生改变时触发
    projectChange (val) {
      console.log(val)
      this.addForm.route_id = ''
      // 获取巡检路线下拉列
      this.fnGetInspectionRouteSelectList(val)
    },
    // 添加巡检班次
    addInspectionClasses (index) {
      var arr = []
      // 遍历所绑定的巡检班次数据，获取其验证规则字段
      this.addForm.list_inspection_classes.forEach((item, index) => {
        arr.push(
                    `list_inspection_classes[${index}].start_time`,
                    `list_inspection_classes[${index}].end_time`,
                    `list_inspection_classes[${index}].inspector_id`
        )
      })
      // 用字段标识验证通过的数据
      var newErr = 0
      // 验证表单
      this.$refs.addFormRef.validateField(arr, err => {
        if (err) {
          return false
        } else {
          // 累计验证通过的数量
          newErr++
        }
      })
      // 若验证通过的数量与验证规则字段相等，则表示全部验证通过
      if (arr.length === newErr) {
        var currentDate = this.$moment().format('YYYY-MM-DD ')
        // 若巡检班次小于4次，则添加一巡检班次
        if (this.addForm.list_inspection_classes.length < 4) {
          // 判断最后一个班次的结束时间是否是23:59，若是，则不添加班次
          if (this.addForm.list_inspection_classes[this.addForm.list_inspection_classes.length - 1].end_time && this.addForm.list_inspection_classes[this.addForm.list_inspection_classes.length - 1].end_time === '23:59:59') {
            this.$msg.warning('已达选择时间上限！')
            return
          }
          // 下一开始时间为上一结束时间加1s，巡检人员为上一巡检人员
          var h = this.$moment(currentDate + this.addForm.list_inspection_classes[this.addForm.list_inspection_classes.length - 1].end_time).add(1, 's').format('HH:mm:ss')
          this.addForm.list_inspection_classes.push({
            start_time: h,
            end_time: '',
            inspector_id: this.addForm.list_inspection_classes[this.addForm.list_inspection_classes.length - 1].inspector_id
          })
        }
      }
    },
    // 删除巡检班次
    delInspectionClasses (index) {
      this.addForm.list_inspection_classes.splice(index, 1)
    },
    // 开始时间限制
    toStartTime (index) {
      var currentDate = this.$moment().format('YYYY-MM-DD ')
      var h
      if (index > 0) {
        // 判断上一巡检班次的结束时间是否存在
        if (this.addForm.list_inspection_classes[index - 1].end_time) {
          h = this.$moment(currentDate + this.addForm.list_inspection_classes[index - 1].end_time).add(1, 'm').format('HH:mm:ss')
          // 存在，则时间限制为 上一结束时间+1  ~ 23:59:59
          return h + '-23:58:00'
          // 判断该班次的结束时间是否存在
          // if (this.addForm.list_inspection_classes[index].end_time) {
          //   // 若存在，则时间限制为 上一结束时间+1  ~ 该结束时间 -1
          //   var t = this.$moment(currentDate + this.addForm.list_inspection_classes[index].end_time).subtract(1, 'm').format('HH:mm:ss');
          //   return h + '-' + t;
          // } else {
          //   // 不存在，则时间限制为 上一结束时间+1  ~ 23:59:59
          //   return h + '-23:58:00';
          // }
        }
      }
    },
    // 开始时间发生改变触发
    changeStartTime (index, time) {
      var currentDate = this.$moment().format('YYYY-MM-DD ')
      // 判断当前班次的结束时间是否存在
      if (this.addForm.list_inspection_classes[index].end_time) {
        // 判断当前班次的开始时间是否大于结束时间，若是，则清空当前结束时间及之后的开始与结束时间
        if (this.$moment(currentDate + time).diff(this.$moment(currentDate + this.addForm.list_inspection_classes[index].end_time)) >= -5900) {
          this.addForm.list_inspection_classes[index].end_time = ''
          this.addForm.list_inspection_classes.forEach((item, i) => {
            if (i > index) {
              item.start_time = ''
              item.end_time = ''
            }
          })
        }
      }
    },
    // 结束时间限制
    toEndTime (index) {
      var currentDate = this.$moment().format('YYYY-MM-DD ')
      var h
      // 判断当前开始时间是否存在，若存在，则限制时间为 开始时间+1 ~ 23:59:59
      if (this.addForm.list_inspection_classes[index].start_time) {
        h = this.$moment(currentDate + this.addForm.list_inspection_classes[index].start_time).add(1, 'm').format('HH:mm:ss')
        // console.log(h);
        return h + '-23:59:59'
      }
    },
    // 结束时间发生改变触发
    changeEndTime (index, time) {
      var currentDate = this.$moment().format('YYYY-MM-DD ')
      // 判断下一班次是否存在
      if (this.addForm.list_inspection_classes[index + 1]) {
        // 若存在，则判断开始时间是否存在
        if (this.addForm.list_inspection_classes[index + 1].start_time) {
          // 当前结束时间 是否 大于 下一开始时间，若大于，则清除之后的开始与结束时间
          if (this.$moment(currentDate + time).diff(this.$moment(currentDate + this.addForm.list_inspection_classes[index + 1].start_time)) >= 5900) {
            this.addForm.list_inspection_classes.forEach((item, i) => {
              if (i > index) {
                item.start_time = ''
                item.end_time = ''
              }
            })
          }
        }
      }
    },
    // 保存
    submitAddForm (plan_status) {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          var obj = {}
          obj.project_id = this.addForm.project_id
          obj.plan_name = this.addForm.plan_name
          obj.route_id = this.addForm.route_id
          obj.plan_start_time = this.addForm.plan_start_time
          obj.plan_end_time = this.addForm.plan_end_time
          obj.work_type = this.addForm.work_type
          obj.work_set = ',' + this.addForm.work_set.join(',') + ','
          obj.open_time = this.addForm.open_time
          obj.list_inspection_classes = this.addForm.list_inspection_classes
          obj.plan_status = plan_status
          console.log(obj)
          if (this.title === '新增巡检计划') {
            this.fnAddInspectionPlan(obj)
          } else {
            obj.Id = this.id
            this.fnUpdateInspectionPlan(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增巡检计划
    async fnAddInspectionPlan (obj) {
      const res = await addInspectionPlan(obj)
      console.log('新增巡检计划---', res)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 巡检计划编辑
    async fnUpdateInspectionPlan (obj) {
      const res = await updateInspectionPlan(obj)
      console.log('巡检计划编辑---', res)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    }
  }
}
</script>

<style scoped lang="less">
.title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
}

.height_box {
    width: 100%;
    height: 30px;
}

.demo-ruleForm {
    max-height: 650px;
    overflow-y: auto;

    .el-select,
    .el-date-editor,
    .el-input {
        width: 400px;
    }

    .time-frame {
        .content_box {
            display: flex;
            align-items: center;
            margin-bottom: 22px;

            .title_box {
                width: 88px;
                text-align: right;
                padding-right: 12px;
            }

            .span_box {
                display: inline-block;
                padding: 0 10px;
            }
        }

        /deep/ .el-form-item__content {
            margin-left: 0 !important;
        }

        .el-form-item {
            display: flex;
            margin-left: 0;
        }

        .el-select,
        .el-date-editor,
        .el-input {
            width: 200px;
        }
    }

    /deep/ .work_type_item {
        .el-form-item__content {
            display: flex;
            margin-left: 0;
        }
    }
}

.box-card {
    position: relative;
    padding-bottom: 30px;

    .checkButton {
        position: absolute;
        right: 10px;
    }

    .checkButton2 {
        position: absolute;
        right: 150px;
    }

    .checkButton3 {
        position: absolute;
        right: 260px;
    }
}</style>
