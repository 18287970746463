<template>
  <div style="margin-top: 20px;">
    <!-- 巡检计划 -->
    <div class="box-card" style="text-align: left">
        <!-- 查询表单 -->
        <el-form
          :inline="true"
          size="small"
          label-position="right"
          class="query_form"
          :model="queryForm"
          ref="queryFormRef"
          @submit.native.prevent
        >
          <el-form-item label="巡检计划：">
            <el-input
              maxlength="30"
              v-model="queryForm.plan_name"
              @keyup.enter.native="onSubmit()"
              @input="(e) => (queryForm.plan_name = validSpace(e))"
              placeholder="请输入巡检计划"
            ></el-input>
          </el-form-item>
          <el-form-item label="资产项目：">
            <el-input
              maxlength="30"
              v-model="queryForm.project_name"
              @keyup.enter.native="onSubmit()"
              @input="(e) => (queryForm.project_name = validSpace(e))"
              placeholder="请输入资产项目"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select
              popper-class="my-select"
              clearable
              v-model="queryForm.plan_status"
              placeholder="请选择巡检计划状态"
              @clear="queryForm.plan_status = null"
              @change="onSubmit()"
            >
              <el-option
                v-for="item in planStatusList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
              <!-- <el-option v-for="item in getOptions('PlanStatus')" :key="item.value" :label="item.name" :value="item.value"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()"
              >查询</lbButton
            >
          </el-form-item>
        </el-form>
        <le-card title="巡检计划">
          <template slot="heard-right">
            <div class="right">
          <lbButton
            type="err"
            icon="shanchu1"
            @click="batchDel"
            v-if="isShowBtn('be0e01bb02a84aee81de3803')"
            >删除</lbButton
          >
          <lbButton
            icon="xinzeng"
            @click="handelInspectionPlan('new')"
            v-if="
              this.getUserInfo.user_name != 'admin' &&
              isShowBtn('89f6d58a824c43108cf14f74')">新增</lbButton
          >
          <!-- <el-button size="small" type="primary" icon="iconfont lebo-tubiaoadd" @click="$router.push('/look-inspection-plan/id')" v-if='this.getUserInfo.user_name != "admin"'>查看</el-button> -->
        </div>
          </template>
          <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
              <template slot-scope="{row}" slot="plan_start_time">
                {{ $moment(row.plan_start_time).format("YYYY-MM-DD") }} 至
              {{ $moment(row.plan_end_time).format("YYYY-MM-DD") }}
            </template>
              <template slot-scope="{row}" slot="plan_status">
                <span :style="'color:' + planStatusColor(row.plan_status)">
                {{ planStatus(row.plan_status) }}
              </span>
            </template>
             <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton
                type="succes"
                icon="chakan"
                hint="查看"
                @click="lookInspectionPlan(row.Id)"
                v-if="row.plan_status != 1"
              ></lbButton>
              <lbButton
                type="warning"
                icon="bianji"
                hint="编辑"
                @click=" handelInspectionPlan(row.Id)"
                v-if="
                  row.plan_status == 1 &&
                  isShowBtn('a9cab0e629c840129eb105f8')
                "
              ></lbButton>
              <lbButton
                size="info"
                icon="chenggong1"
                hint="开启计划"
                @click="
                  isPlanStatusDialog = true;
                  detail = row;
                "
                v-if="
                  row.plan_status == 1 &&
                  isShowBtn('3faf32e92acb4e849dad04f0')
                "
              ></lbButton>
              <lbButton
                type="setting"
                icon="iconclose"
                hint="终止计划"
                @click="
                  isPlanStatusDialog = true;
                  detail = row;
                "
                v-if="
                  row.plan_status == 2 &&
                  isShowBtn('9e57f13ab1184ed1a68c7d5e')
                "
              ></lbButton>
            </template>
          </avue-crud> -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            header-align="center"
          ></el-table-column>
          <el-table-column
            prop="plan_name"
            label="巡检计划"
            min-width="80"
          ></el-table-column>
          <el-table-column label="起止日期" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.plan_start_time).format("YYYY-MM-DD") }} 至
              {{ $moment(scope.row.plan_end_time).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="project_name"
            label="资产项目"
            min-width="80"
          ></el-table-column>
          <el-table-column
            prop="route_name"
            label="巡检路线"
            min-width="80"
          ></el-table-column>
          <el-table-column
            prop="user_name"
            label="巡检人员"
            min-width="80"
          ></el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <span :style="'color:' + planStatusColor(scope.row.plan_status)">
                {{ planStatus(scope.row.plan_status) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="140">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <lbButton
                type="succes"
                icon="chakan"
                hint="查看"
                @click="lookInspectionPlan(scope.row.Id)"
                v-if="scope.row.plan_status != 1"
              ></lbButton>
              <lbButton
                type="warning"
                icon="bianji"
                hint="编辑"
                @click=" handelInspectionPlan(scope.row.Id)"
                v-if="
                  scope.row.plan_status == 1 &&
                  isShowBtn('a9cab0e629c840129eb105f8')
                "
              ></lbButton>
              <lbButton
                size="info"
                icon="chenggong1"
                hint="开启计划"
                @click="
                  isPlanStatusDialog = true;
                  detail = scope.row;
                "
                v-if="
                  scope.row.plan_status == 1 &&
                  isShowBtn('3faf32e92acb4e849dad04f0')
                "
              ></lbButton>
              <lbButton
                type="setting"
                icon="iconclose"
                hint="终止计划"
                @click="
                  isPlanStatusDialog = true;
                  detail = scope.row;
                "
                v-if="
                  scope.row.plan_status == 2 &&
                  isShowBtn('9e57f13ab1184ed1a68c7d5e')
                "
              ></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
        </le-card>

    </div>
    <!-- 开启或者终止计划对话框 -->
    <lebo-dialog
      :title="(detail.plan_status === 1 ? '开启' : '终止') + '计划'"
      :isShow="isPlanStatusDialog"
      width="30%" @close="isPlanStatusDialog=false" footerSlot
    >
      <p style="text-align: left; text-indent: 2em">
        您确定立即{{ detail.plan_status === 1 ? "开启" : "终止" }}该计划吗？
      </p>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isPlanStatusDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="SetInspectionPlan()">保 存</lbButton>
      </span>
    </lebo-dialog>
     <!-- 新增编辑巡检计划弹框 -->
     <lebo-dialog :title="dialogInspectionPlanId==='new'?'新增巡检计划':'编辑巡检计划'" :isShow="showInspectionPlan" width="55%" @close="showInspectionPlan=false" footerSlot>
     <addInspectionPlanComponent v-if="showInspectionPlan" :id="dialogInspectionPlanId" @closeDialog="closeInspectionPlan"></addInspectionPlanComponent>
    </lebo-dialog>
    <!-- 查看巡检计划 -->
    <lebo-dialog title='查看巡检计划' :isShow="showLookInspectionPlan"  width="55%" @close="showLookInspectionPlan=false" footerSlot closeOnClickModal>
     <lookInspectionPlan v-if="showLookInspectionPlan" :id="dialogInspectionPlanId" @closeDialog="closeInspectionPlan"></lookInspectionPlan>
    </lebo-dialog>
  </div>
</template>

<script>
import {
  getInspectionPlanList,
  addInspectionPlan,
  updateInspectionPlan,
  delInspectionPlan,
  getInspectorSelectList,
  getInspectorPlanUpdateDetail,
  setInspectionPlanStatus,
  getInspectorPlanDetail
} from '@/api/assetsManagement'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addInspectionPlanComponent from './components/addInspectionPlan/index.vue'
import lookInspectionPlan from './components/lookInspectionPlan/index.vue'
export default {
  components: { addInspectionPlanComponent, lookInspectionPlan },
  data () {
    return {
      queryForm: {
        plan_name: '',
        project_name: '',
        // 计划状态 1：待开启 2：已开启 3：已终止 4：已结束
        plan_status: null,
        PageIndex: 1,
        PageSize: 10
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
      // 是否展示开启、终止计划对话框
      isPlanStatusDialog: false,
      detail: {},
      planStatusList: [
        { name: '待开启', value: 1 },
        { name: '执行中', value: 2 },
        { name: '已终止', value: 3 },
        { name: '已结束', value: 4 }
      ],
      showInspectionPlan: false,
      showLookInspectionPlan: false,
      dialogInspectionPlanId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '巡检计划',
            prop: 'plan_name'
          }, {
            label: '起止日期',
            prop: 'plan_start_time',
            slot: true
          }, {
            label: '资产项目',
            prop: 'project_name'
          }, {
            label: '巡检路线',
            prop: 'route_name'
          }, {
            label: '巡检人员',
            prop: 'user_name'
          }, {
            label: '状态',
            prop: 'plan_status',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetInspectionPlanList()
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getUserInfo', 'getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取巡检计划列表
    async fnGetInspectionPlanList () {
      const res = await getInspectionPlanList({
        plan_name: this.queryForm.plan_name,
        project_name: this.queryForm.project_name,
        plan_status: this.queryForm.plan_status
          ? this.queryForm.plan_status
          : 5,
        PageIndex: this.queryForm.PageIndex,
        PageSize: this.queryForm.PageSize
      })
      console.log(res)
      this.tableData =
        res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetInspectionPlanList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetInspectionPlanList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetInspectionPlanList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetInspectionPlanList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      //  // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetInspectionPlanList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 点击删除按钮
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm(
          '删除该巡检计划后，已生成的待执行巡检任务则全部改为终止状态，确定该删除吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i].Id)
              if (arr.length === this.multipleSelection.length) {
                obj.Id = arr.join(',')
                this.fnDelInspectionPlan(obj)
                // console.log('删除巡检计划请求');
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的巡检计划！')
      }
    },
    async fnDelInspectionPlan (obj) {
      const res = await delInspectionPlan(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetInspectionPlanList()
    },
    // 开启或终止计划请求
    async SetInspectionPlan () {
      var obj = {}
      obj.Id = this.detail.Id
      // 计划状态 2：开启 3：终止
      if (this.detail.plan_status === 1) {
        // 开启
        obj.plan_status = 2
      } else {
        // 终止
        obj.plan_status = 3
      }
      const res = await setInspectionPlanStatus(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.fnGetInspectionPlanList()
      }
      this.isPlanStatusDialog = false
    },
    // 新增编辑巡检计划
    handelInspectionPlan (id) {
      this.showInspectionPlan = true
      this.dialogInspectionPlanId = id
    },
    // 关闭弹窗
    closeInspectionPlan () {
      this.showInspectionPlan = false
      this.showLookInspectionPlan = false
      this.fnGetInspectionPlanList()
    },
    lookInspectionPlan (id) {
      this.dialogInspectionPlanId = id
      this.showLookInspectionPlan = true
    }
  }
}
</script>

<style scoped lang="less">
</style>
